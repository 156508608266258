<template>
  <div class="home">
 <b-row v-if="true">
      <b-col cols="12">
         <br/>
        <h4 style="text-align: center; background: #acacac; color: #fff; padding: 16px 30px; border-radius: 3px 3px 0 0;">Services</h4>
        <br/>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/contacts/contactSearch')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/contact-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">HR</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/stockSearch')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/growth-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">FINANCE</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/gov-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">TAX</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/training-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">TRAINING</h6>
        </b-card>
      </b-col>
       <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/safety-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">SAFETY</h6>
        </b-card>
      </b-col>
       <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
          <h1 class="text-center">   <img src="@/assets/maindashboardicons/more-icon-01.gif" alt=""  width="50%"></h1>
          <h6  class="mb-0 pb-0 text-center">MORE</h6>
        </b-card>
      </b-col>
    </b-row>

  
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Services',
  data: () => ({
  }),
  created(){
    this.setBreadcrumb([
      {
        text: 'Services',
        active: true
      }
    ])
  },
  methods: {
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),
      navigateTo(route){
        this.$router.push({path: route})
      }
  }
}
</script>
